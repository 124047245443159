.dyn.root {
  overflow: auto;
  position: relative;
  overflow-anchor: none;
  overscroll-behavior-y: contain;
}

.dyn .spacing {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: -1;
}

.dyn .container-x {
  position: absolute;
  height: 100%;
  left: 0;
}
.dyn .container-y {
  position: absolute;
  width: 100%;
  top: 0;
}

.dyn .extra-x {
  position: absolute;
  overflow: hidden;
  height: 100%;
  left: 0;
}

.dyn .extra-y {
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
}
