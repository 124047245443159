.reader {
  /* overflow: auto; */
  position: relative;
  background: var(--reader-page-background);
}

.readerInner {
  /* overflow: auto; */
  position: relative;
}

.ruler {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: -1;
}

.rulerItem {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 8px;
}
.pages {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}
.fixedPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: var(--reader-page-background);
}

.page {
  position: absolute;
  top: 0;
  left: calc(100% * calc(var(--page-index) + var(--page-index-base)));
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: var(--reader-page-background);
}

.extraPage {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.extraPage > * {
  flex: 1 1 auto;
}

.fillerWrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: calc(100% * var(--page-count));
  height: 100%;
}
.filler {
  flex: 1 0 auto;
  position: relative;
  overflow-y: hidden;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.paragraphItem {
  position: absolute;
  /* top: 0; */
  left: 0;
  right: 0;
  padding: 0 8px;
  overflow: hidden;
}
.pageCounter {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  white-space: nowrap;
  background: rgba(255, 255, 255, 0.5);
  color: black;
}

.itemMask {
  position: absolute;
  left: 0;
  top: 0;
}

.itemWrap {
  transform-origin: 0 0;
}

.pageEffect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.3) 30%, rgba(0, 0, 0, 0))
}